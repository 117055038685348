import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DataService } from './data.service';
import { HttpClientModule } from '@angular/common/http';
import { SubmenusPipe } from './submenus.pipe';
import { LanguajeService } from './languaje.service';
import { TreatmentsComponent } from './treatments/treatments.component';
import { FaqComponent } from './faq/faq.component';
import { GalleryComponent } from './gallery/gallery.component';

// the main connector. must go first
import { FullCalendarModule } from '@fullcalendar/angular'; 
import interactionPlugin from '@fullcalendar/interaction';
//plugings
import dayGridPlugin from '@fullcalendar/daygrid'; 
import timeGridPlugin from '@fullcalendar/timegrid'; 

import { ReservationsComponent } from './reservations/reservations.component';
import { AboutComponent } from './about/about.component'; 
import { FormsModule } from '@angular/forms';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PretreatmentComponent } from './pretreatment/pretreatment.component';
import { BiosecurityComponent } from './biosecurity/biosecurity.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SubmenusPipe,
    TreatmentsComponent,
    FaqComponent,
    GalleryComponent,
    ReservationsComponent,
    AboutComponent,
    TestimonialsComponent,
    PretreatmentComponent,
    BiosecurityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FullCalendarModule,
    FormsModule
  ],
  providers: [
    DataService,
    LanguajeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
