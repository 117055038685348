<div class="tab-content">
    <div *ngIf="languaje=='english'" class="col-12">
        <h2>THE PROFESSIONALS IN PERMANENT COSMETICS AND THE NEW REALITY.</h2>
        <p>
            Never before have we been so affected at work until the appearance and worldwide spread of the SARS
            Co V-2 virus, which was later called COVID-19.
        </p>
        <p>
            We all suffered from the gradual arrival of the virus in our countries, in our lives and in our work. The
            OMS dictated the sanitary rules to be observed throughout the planet upon arrival and among them the
            prohibition of opening the studios and serving the clientele.
        </p>
        <p>
            There was a lot of information, but it was confusing because some facts changed from day to day. This is
            caused by the development of the virus that was unknown and that causes different havoc depending
            on the age and health conditions of the population it affects.
        </p>
        <p>
            Depending on the latitudes of the planet and the behavior of the local population, an attempt was
            made to control the statistics of affectation, but the Asian and European examples with the high
            numbers of sick people and deaths did not predict anything good for America.
        </p>
        <p>
            The United States was seriously affected and then Mexico and South America, which due to their lack of
            prevention and their economic condition, did not want or could not prepare well for the crisis where the
            calculations reflected that the health systems would not be enough to care for so many sick and was
            fulfilled. The quarantine arrived with its particular difficulties so different for each person, each
            colleague and their families. It is difficult to comment on exactly how it was and how it was held because
            in my country and in others we are still immersed in it. In addition, in places where its activity was
            considered controlled, new outbreaks of COVID-19 have emerged.
        </p>
        <p>
            Recently in the U.S. The suspension of activities was gradually ended and the booths for permanent
            cosmetic professionals have opened their doors, beginning their return to work. Faced with the
            resurgence of COVID 19 where it was thought to be controlled, we must remain cautious as this is a
            permanent threat.
        </p>
        <p>
            We are going to talk about the profile of the professional technician in Permanent Cosmetic Makeup
            post COVID-19 pandemic. We will share some suggestions that are already a reality for us regarding the
            return to work based on the basis that people know little about the risk circumstances around the virus
            and therefore return to work must be under the rules simple but important for everyone.
        </p>
        <h3>COVID-19 and Permanent Cosmetics.</h3>
        <p>
            The pandemic will bring us valuable learning: How to face the new challenges in the Biological,
            Psychological, Economic and Social fields.
        </p>
        <p>
            A virus that came to stop the life of population centers almost the entire planet, which affected the
            ordinary life of people and their free movement to impose a quarantine that forced them to constantly
            live with their closest relatives, triggering various reactions and behaviors, not always positive.
        </p>
        <p>
            The impact on the economy that was accentuated in people who lost their jobs and now have fewer or
            no resources and purchasing power. The social complexity of a return to daily life uncertain by the other
            three factors mentioned above
        </p>
        <h3>What is and what does COVID-19 do?</h3>
        <p>
            This virus has the respiratory system as its target organs, as it begins in the nose and mouth,
            communicating with the lacrimal canal that starts from the eye. This indicates that anatomically it limits
            us two important areas of work that are eyelids and lips.
        </p>
        <p>
            This scenario is bad if we stay like this, but it is an area of opportunity if we now focus on paramedical
            jobs and even on body tattoos.
        </p>
        <div class="col-md-6 offset-md-3 col-12" style="text-align: center;">
            <img src="/assets/images/covid.png" class="img-fluid rounded">
        </div>
        <p>
            <b>COVID-19</b> resides in the respiratory tract of healthy and sick carriers, reaches the mouth and nose,
            which is a drier mucosa. It will spread through people's coughs and sneezes that propel the tiny droplets
            of saliva up to 6 feet away. The heavy drops fall to the floor or nearby surfaces and depending on the
            material in question, the vitality of the virus will vary from hours to days and if someone contacts their
            hand and does not wash and disinfect them frequently, in a movement that takes their hands to your
            face, it can cause the virus to enter your respiratory system by becoming infected in this way. That is
            why the return to work requires from professionals in Permanent Cosmetic Makeup an understanding of
            the disease, its form of transmission and how to protect itself from it when doing applications or
            procedures.
        </p>
        <p>
            Let us place the articles that we regularly use on the work table and, according to the table, think about
            the vitality of the virus in case it becomes contaminated.
        </p>
        <div class="col-md-6 offset-md-3 col-12" style="text-align: center;">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">SURFACE</th>
                        <th scope="col">ACTIVE VIRUS PERMANENCE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PAPER</td>
                        <td>3 HOURS</td>
                    </tr>
                    <tr>
                        <td>PAPERBOARD</td>
                        <td>3 HOURS</td>
                    </tr>
                    <tr>
                        <td>WOOD</td>
                        <td>2 DAYS</td>
                    </tr>
                    <tr>
                        <td>CLOTH</td>
                        <td>2 DAYS</td>
                    </tr>
                    <tr>
                        <td>GLASS</td>
                        <td>4 DAYS</td>
                    </tr>
                    <tr>
                        <td>MONEY</td>
                        <td>4 DAYS</td>
                    </tr>
                    <tr>
                        <td>PLÁSTIC</td>
                        <td>7 DAYS</td>
                    </tr>
                    <tr>
                        <td>STEEL</td>
                        <td>3 DAYS</td>
                    </tr>
                    <tr>
                        <td>FACE MASK</td>
                        <td>7 DAYS</td>
                    </tr>
                    <tr>
                        <td>NASAL OR SALIVA DROPS</td>
                        <td>3 DAYS</td>
                    </tr>
                    <tr>
                        <td>CLOTHS</td>
                        <td>8 DAYS</td>
                    </tr>
                    <tr>
                        <td>COOPER</td>
                        <td>4 HOURS</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <P>
            A modification in the habits and customs of how the new reality was worked before, which is how the
            transitional stage has been called between the beginning of the pandemic and that uncertain future
            moment in which medicines and a specific vaccine are available, is necessary. to prevent and cure the
            human population against Coronavirus SARS CV-2.
        </P>
        <p>
            <b>What are those modifications?</b> We start from a step-by-step planning based on the new reality. Plan
            the
            steps that we must follow to resume working with the problems that the pandemic brought with it, such
            as that at the hospital level, the urgent requirement of having medical equipment while the factories of
            the same stopped their production created a shortage, so elements of the PPE became more expensive
            making it problematic to acquire it as before.
        </p>
        <p>
            Planning your current situation and futurizing on various possible scenarios with the health issue as the
            center. The establishment and compliance with the new health protocols adhering to national and
            international regulations, as this is being handled by the WHO. As a responsibility and commitment, the
            technician will carry out the progressive application towards the new reality supported by correct
            training, updating necessary advice and finally apply it at work. Never forget and assume that everything
            will be different.
        </p>
        <p>
            Determine what your personal needs and those of your business will be. Determine how great the
            potential risk of contagion is in your workplace. Investigate the situation of vulnerability of you and your
            clients.
        </p>
        <ul>
            <li>
                Keep applying international rules.
            </li>
            <li>
                In the presence of symptoms of respiratory disease, stay home!
            </li>
            <li>
                Encourage a minimum distance of 6 feet between people at work
            </li>
            <li>
                Decrease in the frequency and face-to-face encounters between workers.
            </li>
            <li>
                You will adapt the spaces and work areas to reduce human density during the maximum, high
                and medium levels.
            </li>
        </ul>
        <p>
            Hygiene measures will continue to be observed, especially that of the hands, respiratory hygiene when
            sneezing or coughing, continue to take care of physical distancing and when there is any suspicion of
            illness, invite the person to leave and have an effective recovery. One of the situations that are
            reality
            for workers in Permanent Cosmetics, is the attention given to certain clients: The elderly or elderly
            women, pregnant, obese, diabetic and uncontrolled hypertensive, people with cancer, HIV, some people
            with disabilities, people with cardiovascular and autoimmune diseases, those with asthma and COPD,
            smokers, patients with hepatitis, those who have exceeded the use of corticosteroids, those who have
            kidney failure or have been transplanted. The classification of occupational hazards places us in the
            yellow color which means medium risk due to the nature of our work, frequent contact and close
            exposure to sources with the possibility of contagion. We are hard-working people with highly frequent
            contact with the general public, co-workers and clients. And that is a study of Permanent Cosmetic
            Makeup.
        </p>
        <p>
            <b>Protocols that we will develop and that we will comply</b>, with Given this scenario, we must design
            different protocols for the risk of work and carefully obey them. As they are in writing, we will be able to
            review and refine them as health situations in our environment change or the authorities dictate new
            rules.
        </p>
        <p>
            <b>Cleaning products protocols. </b>To be graphic, a Permanent Cosmetic Makeup business is divided into
            three areas: black, gray and white. Consider that the studio has a common area that we call black and
            that is where we contact the public. To that household cleaning and disinfection products are used, in
            the gray area that is where the materials and equipment are kept
        </p>
        <p>
            At work, other disinfectant products are used that are purchased at the pharmacy for sale on the
            shelves, and the work booth is where EPA-authorized disinfectants are used to prevent the spread of
            COVID-19.
        </p>
        <p>
            Regarding personal protective equipment, it is recommended to have the following and in sufficient
            quantities: Disposable long-sleeved gown / plastic apron / mouth cover N-95, caps, face shield or masks,
            safety glasses with side, upper and lower protection. lower eyes, plastic barrier, bib, nitrile gloves,
            plastic sleeves, shoe covers. The masks must be available for both artists (N95 Respirator) and mouth
            covers for clients. In PMU and microblading I recommend reinforcing the mouth covers with a plastic
            barrier. Artists must use a second layer of protection, which is face shields or masks.
        </p>
        <p>
            <b>Adaptation to the business. </b>In your business, you must install physical barriers at the customer
            treatment sites, protecting the front and sides between customer and technician. The workstations in
            multiple cabins are delimited with washable, fixed =, mobile, hanging, etc. physical barriers. Otherwise,
            the work stations are delimited with signs or markings on the floor ensuring a minimum distance of 6
            feet between technicians. It favors good ventilation to disperse droplets of saliva with COVID-19. And
            you must also wash and disinfect the extraction and ventilation systems. Maintain a relative humidity of
            40% to 60%. To guide the attendees, we must place signs of work areas and indicate to the client what is
            expected to be respected and fulfilled. Bathroom asepsis is essential before and after someone uses the
            bathroom. Facilities for hand washing and sanitizing should be near the entrance with paper towels and
            never cloth. It should have step-by-step signs on how to wash hands properly and toilets should be
            restricted in their use due to possible spread.
        </p>
        <p>
            There should be a training program for staff regarding personal hygiene focused on how and when to
            wash and disinfect their hands. But also referring to your work clothes (if you wash them industrially or
            at home and its transfer) Also to your street clothes. Slow donning and doffing of dirty gloves. The
            correct use of masks or facial coverings and mouth covers. Use EPA substances according to
            manufacturers' instructions to avoid toxicity. Provide and study safety data sheets for cleaning
            chemicals and make sure employees are aware of the dangers of their use. The asepsis of the work
            booth must be carried out in its entirety including floors, walls and furniture, doing this between client
            and client. Before entering and when leaving the place. True body modification professionals
            understand the importance of, know and apply proper aseptic techniques to ensure the safety of clients
            and themselves. The cleaning cloths move from top to bottom, from cleanest to dirtiest and from the
            bottom to the door or to the front. It will have cleaning supplies for the white area and others for the
            black and gray area: Broom, mop, bucket, tray, jargon, cleaning cloths, etc. How is it going activity will
            be more frequent should be trained in how to do it using ergonomic rules to avoid pain and damage to
            the back, especially in the lumbar area. Practice them.
        </p>
        <h3>The Steps of Asepsis:</h3>
        <p>
            Arrive at the business, step on the sanitary mat, wash hands, disinfect hands, store their belongings in
            the bag, disinfect the bag (Client), delivery of mouth covers (Client), correct PPE (Technician), correct
            placement PPE (Client), disinfection of furniture, facilities and equipment (PREVIOUS), equipment lining,
            surgical hand washing. Limited dermography and piercing jobs. Complete final disinfection of furniture,
            facilities and equipment. Proper disposal of the material used EPP and RPBI. Final hand washing and
            disinfection.
        </p>
        <h3>The Steps of Antisepsis:</h3>
        <p>
            Washing and disinfection of the skin, exfoliation, anesthesia (PMU, MB), profiling, plastic nose and
            mouth blocking. Secondary disinfection of the area, cleaning of the skin in the development of
            dermography.
        </p>
        <p>
            Aftercare that you will do at home (Comment).
        </p>
        <h3>Customer arrival:</h3>
        <p>
            The disinfection of shoes that can carry viruses from people who spit on the street is necessary and it is
            recommended that you have your sanitary mat and force everyone who enters to step on it. Large
            plastic bags must be available. This is one of the modifications to the service and consists of providing
            the customer with a plastic bag to store all their personal items including cell phones. If you determine,
            you will probably attach a plastic container to store it. The bag and container will be disinfected.
        </p>
        <p>
            <b>Mandatory mouth cover: </b>For greater hygiene of the site, a new mouth cover will be provided to the
            client and the old one will be thrown away. The client will correctly place the mouth cover that will be
            provided. Depending on what the technician decides, either it takes you directly to the area where the
            appointment will take place, or to the procedure booth (white area). Some artists may request that
            clients wear polypropylene gloves.
        </p>
        <p>
            <b>Sales Areas:</b> Let us ensure that the payment is made in advance by deposit or bank transfer to avoid
            handling the money. In case of receiving it, we suggest having a container with a homemade cleaning
            product and immerse it for a few minutes to reduce its bacterial load. If you have items for sale on the
            counter in addition to the physical barrier, do not allow people to touch more than what they are
            actually going to purchase. Disinfect everything that people have touched and not bought. Disinfect the
            keyboards of your point of sale terminals.
        </p>
        <p>
            <b>Reception of Packages in the Local:</b> They must be received at the door of the premises or isolated
            area
            and disinfected immediately. Open them and discard the packaging in the trash. Take out the content
            and disinfect it. Store it where it belongs, wash and disinfect your hands.
        </p>
        <p>
            <b>Conversation within the premises should be limited.</b> An explanatory poster informs the client that
            during a conversation there is an increase in the expulsion of saliva particles, so it will be avoided as
            much as possible. Always thinking about the contagion of COVID-19.
        </p>
        <p>
            There is evidence that through the inner part of the eye there are passages to the nose, and that nasal
            breathing produces less moisture than the oral mucosa, and that the nasal passage is a first line of
            defense. Micropigmenters should wear gloves when touching the client, this includes from brow
            mapping or other design work on their face. In an early stage of re-entry to work, the eyelid and lip lines
            should not be made, both profile and filling. Professionals may consider only micro pigmentation of the
            eyebrows, and paramedical procedures from the neck down to avoid contact with the eyes, nose and
            mouth. Therefore, scalp techniques, scar camouflage, nipple / areola complex, would be recommended.
            Clients should wear covers, especially for brow procedures because they often sneeze. The trigeminal
            nerves transmit sensations from the nose, eyebrows, eyelids, and other parts of the face to the brain.
            When those nerves are stimulated, pulling the hair from the eyebrows, or itching those areas can cause
            sneezing. We recommend placing a plastic or plastic osmotic barrier on top of the mouth guard. Discuss
            with the client the discomfort she will experience.
        </p>
        <p>
            It is necessary to understand about the possible adherence and permanence of COVID-19 in the clothes,
            ornaments and accessories of the artist (workers) in what we know as fomites. There are places where
            uniforms are worn. In these places you should avoid the use of: Cloth gowns, you will have to cover your
            street clothes, especially long-sleeved blouses. You may not wear ties or jewelry such as chokers or
            necklaces. Men may not have a mustache or beard. Not only because of the coronavirus but also
            because of other microorganisms that are housed there. During the procedure to the new normal,
            promote that the technical workers do not share work instruments and / or personal objects. Everything
            must be constantly disinfected. For reasons of space I will end here with this article.
        </p>
        <p>
            <b>
                The last recommendation: Personal care and prevention as long as there is no preventive vaccine or
                anti-viral drug for Coronavirus SARS Cov-2.
            </b>
        </p>
    </div>
    <div *ngIf="languaje!='english'" class="col-12">
        <h2>LOS PROFESIONALES EN COSMÉTICOS PERMANENTES Y LA NUEVA REALIDAD.</h2>
        <p>
            Nunca antes habíamos sido tan afectados laboralmente hasta la aparición y diseminación a nivel
            mundial del virus del SARS Co V-2 al que posteriormente se le denomino COVID-19.
        </p>
        <p>
            Todos padecimos el arribo paulatino del virus a nuestros países, a nuestras vidas y a nuestro trabajo. La
            OMS dicto las reglas sanitarias a observarse en todo el planeta ante su arribo y entre ellas la prohibición
            de abrir los estudios y atender a la clientela.
        </p>
        <p>
            Había mucha información, pero era confusa porque algunos hechos cambiaban de un día para otro. Esto
            es causado por el desarrollo del virus que era desconocido y que causa estragos distintos según la edad
            y condiciones de salud de la población a la que afecta.
        </p>
        <p>
            Según las latitudes del planeta y el comportamiento de la población local se iba tratando de controlar la
            estadística de afectación, pero los ejemplos asiáticos y europeos con las altas cifras de personas
            enfermas y de defunciones no pronosticaban nada bueno para América.
        </p>
        <p>
            Los Estados Unidos fueron seriamente afectados y después México y Sud América que por su falta de
            prevención y su condición económica no quisieron o no pudieron prepararse bien para la crisis donde
            los cálculos reflejaban que los sistemas de salud no serían suficientes para atender a tantos enfermos y
            se cumplió. Llego la cuarentena con sus dificultades particulares tan distintas para cada persona, cada
            colega y sus familias. E s difícil comentar como fue con precisión como fue y como asido porque en mi
            país y en otros seguimos inmersos dentro de ella. Además, en lugares donde se consideraba controlada
            su actividad, han surgido nuevos brotes del COVID-19.
        </p>
        <p>
            Recientemente en los E.U. se terminó paulatinamente la suspensión de actividades y las cabinas de los
            profesionales de cosméticos permanentes han abierto sus puertas iniciando su retorno al trabajo. Ante
            el resurgimiento de COVID 19 donde se pensaba que estaba controlado, debemos de seguir precavidos
            ya que esta es una amenaza permanente.
        </p>
        <p>
            Vamos a platicar acerca del perfil del técnico profesional en <b>Maquillaje Cosmético Permanente</b> post
            pandemia de COVID-19. Compartiremos algunas sugerencias que ya son para nosotros una realidad en
            cuanto al retorno al trabajo partiendo de la base de la que la gente poco sabe de las circunstancias de
            riesgo en torno al virus y por lo tanto e retorno al trabajo debe de ser bajo las reglas de bioseguridad,
            sencillas pero importantes para todos.
        </p>
        <h3>El COVID-19 y los cosméticos permanentes.</h3>
        <p>
            La pandemia nos traerá un valioso aprendizaje: Como enfrentar los nuevos desafíos en los ámbitos
            Biológico, Psicológico, Económico y Social.
        </p>
        <p>
            Un virus que vino a detener la vida de núcleos poblacionales casi del planeta entero, que afecto la vida
            ordinaria de las personas y su libre tránsito para imponerles una cuarentena que obligaba a convivir
            constantemente con los familiares más cercanos desencadenando diversas reacciones y conductas, no
            siempre positivas.
        </p>
        <p>
            La afectación de la economía que se acentuó en la gente que perdió su trabajo y ahora dispone de
            menos o nulos recursos y poder adquisitivo. La complejidad social de un retorno a la vida cotidiana
            incierto por los otros tres factores anteriormente mencionados.
        </p>
        <h3>¿Qué es y que hace el COVID-19?</h3>
        <p>
            Este virus tiene como órganos meta al sistema respiratorio, como este inicia en la nariz y boca teniendo
            comunicación con el canal lagrimal que parte del ojo. Esto nos indica que anatómicamente nos limita
            dos importantes áreas de trabajo que son parpados y labios.
        </p>
        <p>
            Este panorama es malo si nos quedamos así, pero es un área de oportunidades si ahora nos enfocamos
            hacia los trabajos paramédicos y hasta en el tatuaje corporal.
        </p>
        <div class="col-md-6 offset-md-3 col-12" style="text-align: center;">
            <img src="/assets/images/covid.png" class="img-fluid rounded">
        </div>
        <p>
            <b>El COVID-19</b> reside en el tracto respiratorio de portadores sanos y de enfermos, llega a la boca y a
            la
            nariz, que es una mucosa más seca. Se va a propagar a través de la tos y de estornudos de las personas
            que impulsan las pequeñas gotas de saliva hasta 6 pies de distancia. Las gotas pesadas caen al piso o a
            superficies cercanas y dependiendo del material de que se trate, la vialidad del virus variara desde horas
            hasta días y si alguien contacta con su mano y no se las lava y desinfecta con frecuencia, en un
            movimiento que lleve sus manos a su cara, puede hacer que el virus ingrese a su sistema respiratorio
            infectándose de esta forma. Por eso es que el retorno al trabajo exige de los profesionales en Maquillaje
            Cosmético Permanente de una comprensión de la enfermedad, de su forma de transmisión y de cómo
            protegerse de ella al estar haciendo aplicaciones o procedimientos.
        </p>
        <p>
            Ubiquemos en mesa de trabajo los artículos que regularmente empleamos y de acuerdo al cuadro
            pensemos en la vialidad de los virus en caso de que se llegara a contaminar.
        </p>
        <div class="col-md-6 offset-md-3 col-12" style="text-align: center;">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">SUPERFICIE</th>
                        <th scope="col">PERMANENCIA ACTIVA DEL VIRUS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PAPEL</td>
                        <td>3 HORAS</td>
                    </tr>
                    <tr>
                        <td>CARTON</td>
                        <td>3 HORAS</td>
                    </tr>
                    <tr>
                        <td>MADERA</td>
                        <td>2 DIAS</td>
                    </tr>
                    <tr>
                        <td>TELA</td>
                        <td>2 DIAS</td>
                    </tr>
                    <tr>
                        <td>VIDRIO</td>
                        <td>4 DIAS</td>
                    </tr>
                    <tr>
                        <td>BILLETES</td>
                        <td>4 DIAS</td>
                    </tr>
                    <tr>
                        <td>PLASTICO</td>
                        <td>7 DIAS</td>
                    </tr>
                    <tr>
                        <td>METAL</td>
                        <td>3 DIAS</td>
                    </tr>
                    <tr>
                        <td>MASCARILLAS</td>
                        <td>7 DIAS</td>
                    </tr>
                    <tr>
                        <td>GOTAS NASALES O DE SALIVA</td>
                        <td>3 DIAS</td>
                    </tr>
                    <tr>
                        <td>ROPA</td>
                        <td>8 DIAS</td>
                    </tr>
                    <tr>
                        <td>COBRE</td>
                        <td>4 HORAS</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>
            Se hace necesaria una modificación en los hábitos y costumbres de cómo se trabajaba antes a la nueva
            realidad que es como se le ha llamado a la etapa transicional entre el inicio de la pandemia y ese
            momento incierto futuro en que se cuente con medicamentos y vacuna especifica que prevenga y que
            cure a la población humana en contra del Coronavirus SARS CV-2.
        </p>
        <p>
            <b>¿Cuáles son esas modificaciones? </b>Partimos de una planeación paso a paso basada a la nueva realidad.
            Planear los pasos que debemos seguir para retomar a trabajar con las problemáticas que la pandemia
            trajo consigo como es que a nivel hospitalario la exigencia impostergable de contar con equipo médico
            mientras que las fábricas del mismo pararon su producción creo un desabasto, por lo que elementos del
            PPE se encarecieron haciendo problemático el adquirirlo como antes.
        </p>
        <p>
            Planeación de tu situación actual y futurizar sobre varios escenarios posibles teniendo como centro el
            tema de la salud. El establecimiento y cumplimiento de los nuevos protocolos de salud apegados a los
            reglamentaciones nacionales e internacionales ya que esto lo está manejando la OMS. Como
            responsabilidad y compromiso el técnico realizara la aplicación progresiva hacia la nueva realidad
            respaldada por una correcta capacitación, actualización asesoría necesaria y finalmente la aplicara en el
            trabajo. Nunca olvidar y asumir de que todo será diferente.
        </p>
        <p>
            Determina cuales serán tus necesidades personales y las de tu negocio. Determina que tan grande es el
            riesgo potencial de contagio en tu medio de trabajo. Indaga la situación de vulnerabilidad tuya y de tu
            clientela.
        </p>
        <ul>
            <li>
                Sigue aplicando las reglas internacionales.
            </li>
            <li>
                Ante la presencia de síntomas de enfermedad respiratoria ¡Quédate en casa!
            </li>
            <li>
                Favorecer una distancia mínima de 6 pies entre las personas en el trabajo
            </li>
            <li>
                Disminución de la frecuencia y el encuentro cara a cara entre las personas trabajadoras.
            </li>
            <li>
                Adecuaras los espacios y áreas de trabajo para reducir la densidad humana durante los niveles
                máximo, alto y medio.
            </li>
        </ul>
        <p>
            Las medidas de higiene se seguirán observando, especialmente la de las manos, la higiene respiratoria al
            estornudar o toser, seguir cuidando el distanciamiento físico y cuando haya alguna sospecha de
            enfermedad, invitar a la persona a que se vaya y tenga una recuperación efectiva. Una de las situaciones
            que son realidad para los trabajadores en Cosméticos Permanentes, es la atención que se presta a
            ciertas clientas: Las señoras mayores o de la tercera edad, embarazadas, obesas, diabéticas e
            hipertensas no controladas, personas con cáncer, VIH, algunas personas con discapacidad, personas con
            enfermedades cardiovasculares y autoinmunes, aquellas con asma y COPD, fumadores, enfermas de
            hepatitis, las que han excedido en uso de cortico esteroides, las que tienen insuficiencia renal o han sido
            trasplantadas. La clasificación de riesgos laborales nos ubica en el color amarillo que significa riesgo
            medio por la naturaleza de nuestro trabajo contacto frecuente y cercano exposición a fuentes con
            posibilidad de contagio. Somos personas trabajadoras con contacto altamente frecuente con público en
            general, compañeros de trabajo y clientes. Y eso es un estudio de Maquillaje Cosmético Permanente.
        </p>
        <h3>
            Protocolos que elaboraremos y que cumpliremos
        </h3>
        <p>
            Ante este panorama debemos de ir diseñando protocolos diferentes para el riesgo del trabajo y
            obedecerlos cuidadosamente. Al estar por escrito podremos revísalos y perfeccionarlos conforme se
            modifiquen las situaciones de salud de nuestro entorno o las autoridades dicten nuevas reglas.
        </p>
        <p>
            <b>Protocolos de productos de limpieza.</b> Para ser gráficos un negocio de Maquillaje Cosmético Permanente
            lo separamos en tres áreas: La negra, la gris y la blanca. Considere que el estudio cuenta con un área
            común que le llamamos negra y es donde contactamos con el público. A que se emplean productos de
            limpieza y desinfección casera, en el área gris que es donde se guardan los materiales y el equipo detrabajo
            se emplean otros productos desinfectantes que se adquieren en la farmacia de venta en los
            estantes, y en la cabina de trabajo es donde se emplean desinfectantes autorizados por la EPA para
            prevenir la diseminación del COVID-19.
        </p>
        <p>
            En cuanto a el equipo personal de protección se recomienda tener los siguientes y en cantidades
            suficientes: Bata desechable de manga larga/ delantal de plástico/ cubre bocas N-95, cofias, protector
            facial o caretas, lentes de seguridad con protección lateral, superior e inferior de los ojos, barrera de
            plástico, babero, guantes de nitrilo, mangas de plástico, cubre calzado.
        </p>
        <p>
            Las mascarillas deben de estar disponibles tanto para artistas (Respirador N95) como cubre bocas para
            clientes. En PMU y microblading recomiendo reforzar los cubre bocas con una barrera de plástico. Los
            artistas deben de usar una segunda capa de protección, que son los protectores faciales o caretas.
        </p>
        <p>
            <b>Adaptación al negocio. </b>En tu negocio debes de instalar barreras físicas en los sitios de trato con
            los
            clientes, protegiendo el frente y laterales entre cliente y técnico. Las estaciones de trabajo en cabinas
            múltiples, se delimitan con barreras físicas lavables, fijas =, móviles, colgantes, etc. En caso contrario,
            las
            estaciones de trabajo se delimitan con señalizaciones o marcas en el piso asegurando los 6 pies de
            distancia mínima entre técnicos.
        </p>
        <p>
            Favorece una buena ventilación para dispersar las gotas de saliva con COVID-19. Y además debes lavar y
            desinfectar los sistemas de extracción y ventilación. Mantener una humedad relativa de 40% a 60%.
        </p>
        <p>
            Para orientar a los asistentes debemos colocar señalizaciones de áreas de trabajo e indicarle al cliente lo
            que se espera que se respete y se cumpla.
        </p>
        <p>
            La asepsia del cuarto de baño es indispensable hacerla antes y después de que alguien use el cuarto de
            baño. Las instalaciones para el lavado y la desinfección de manos deben de estar cerca de la entrada con
            toallas de papel y nunca de tela. Debe de tener letreros del paso a paso de como lavarse las manos
            correctamente y los sanitarios deben de restringirse en su uso por la posible propagación.
        </p>
        <p>
            Se debe de tener un programa de capacitación para el personal referido a la higiene personal enfocada
            al cómo y cuándo lavarse y desinfectarse las manos. Pero también referente a su ropa de trabajo (si la
            lava industrialmente o en casa y su traslado) También a su ropa de calle. La colocación y el retiro lento
            de los guantes sucios. El uso correcto de las mascarillas o coberturas faciales y los cubre bocas.
        </p>
        <p>
            Usar las sustancias EPA según las instrucciones de los fabricantes para evitar la toxicidad.
        </p>
        <p>
            Proporcionar y estudiar las hojas de datos de seguridad para productos químicos de limpieza y
            asegurarse de que los empleados sean conscientes de los peligros de su uso.
        </p>
        <p>
            La asepsia de la cabina de trabajo debe realizarse en su totalidad incluyendo pisos, paredes y mobiliario,
            haciendo esto entre cliente y cliente. Antes de ingresarlo y cuando abandone el lugar. Los verdaderos
            profesionales en modificaciones corporales entienden la importancia, conocen y aplican las técnicas
            apropiadas de asepsia para garantizar la seguridad de los clientes y de ellos mismos.
        </p>
        <p>
            Los paños para realizar la limpieza se mueven de arriba hacia abajo, de los más limpio a lo más sucio y
            del fondo hacia la puerta o hacia el frente. Contará con artículos de limpieza para el área blanca y otros
            para el área negra y gris: Escoba, trapeador, cubeta, charola, jerga, trapos de limpiar, etc. Como
            estaactividad será más frecuente debe de capacitarse en cómo hacerla empleando reglas de ergonomía para
            evitar dolor y danos en la espalda especialmente en la zona lumbar. Practíquelos.
        </p>
        <h3>Los Pasos de la Asepsia:</h3>
        <p>
            Llegar al negocio, pisar el tapete sanitario, lavado de manos, desinfección de manos, guarda sus
            pertenencias en la bolsa, desinfecta la bolsa (Cliente), entrega de cubre bocas (Cliente), colocación
            correcta del EPP (Técnico), colocación correcta del EPP (Cliente), desinfección del mobiliario,
            instalaciones y equipo (PREVIO), forro de equipo, lavado de manos quirúrgico. Trabajos limitados de
            dermografía y perforaciones. Desinfección final completo de mobiliario, instalaciones y equipo. Desecho
            adecuado del material empleado EPP y de RPBI. Lavado y desinfección final de manos.
        </p>
        <h3>Los Pasos de la Antisepsia:</h3>
        <p>
            Lavado y desinfección de la piel, exfoliación, anestesia (PMU, MB), perfilado, bloqueo plástico de nariz y
            boca. Desinfección secundaria del área, limpieza de la piel en el desarrollo de la dermografía.
        </p>
        <p>
            Cuidados posteriores que hará en casa (Comentar).
        </p>
        <h3>Arribo del cliente:</h3>
        <p>
            La desinfección de los zapatos que pueden acarrear virus de gente que escupe en la calle se hace
            necesaria y se recomienda que tenga su tapete sanitario y obligue a todos los que entran a pisarlo.
            Debe de tener a disponibilidad bolsas de plástico grandes. Esta es una de las modificaciones en el
            servicio y consiste en proporcionar al cliente una bolsa de plástico para guardar todos sus artículos
            personales incluidos los teléfonos celulares. S i determina, probablemente anexara un contenedor
            plástico para guardarla. La bolsa y el contenedor serán desinfectados.
        </p>
        <h3>Cubre bocas obligatorio:</h3>
        <p>
            Cubre bocas obligatorio:
            Para mayor higiene del sitio se proveerá de un cubre bocas nuevo al cliente y desechará el viejo a la
            basura. El cliente se colocará correctamente el cubre bocas que le será proveído. Según lo que decida el
            técnico, ya sea que le conduce directamente al área donde se llevara a cabo la cita previa, o bien, a la
            cabina del procedimiento (zona blanca). Algunos artistas pueden solicitar que los clientes se pongan
            guantes de polipropileno.
        </p>
        <h3>Áreas de Ventas:</h3>
        <p>
            Procuremos que el pago se haga previamente por deposito o transferencia bancaria para evitar manejar
            el dinero. En caso de recibirlo sugerimos tener un recipiente con un producto de limpieza casero y
            sumergirlo unos minutos para disminuir su carga bacteriana. En caso de tener artículos de venta en el
            mostrador además de la barrera física, no permita que la gente toque más que lo que realmente va a
            adquirir. Desinfecte todo lo que la gente haya tocado y no comprado. Desinfecte los teclados de sus
            terminales punto de venta.
        </p>
        <h3>Recepción de Paquetes en el Local:</h3>
        <p>
            Debe ser recibidos en la puerta del local o área aislada y desinfectarlos de inmediato. Abrirlos y desechar
            el empaque a la basura. Sacar el contenido y desinfectarlo. Guardarlo en donde corresponde, lavarse y
            desinfectarse las manos.
        </p>
        <p>
            La conversación dentro del local debe limitarse. Un cartel explicativo le informa al cliente que durante
            una conversación hay un incremento en la expulsión de partículas de saliva por lo que se evitara al
            máximo. Siempre pensando en la manera de contagio del COVID-19.
        </p>
        <p>
            Hay evidencia de que por la parte interior del ojo hay conductos hacia la nariz, y que la respiración nasal
            produce menos humedad que la mucosa bucal, y que el conducto nasal es una primera línea de defensa.
            Los micropigmentadores deben usar guantes al tocar al cliente, esto incluye desde el mapeo de cejas u
            otro trabajo de diseño en su rostro.
        </p>
        <p>
            En una etapa temprana de reingreso al trabajo, no se debe hacer línea de parpados ni de labios tanto
            perfil como relleno. Los profesionales podrán considerar solo micro pigmentación de cejas, y
            procedimientos paramédicos del cuello hacia abajo para evitar contactar con ojos, nariz y boca. Por lo
            tanto, técnicas de scalp, camuflaje de cicatrices, complejo pezón/areola, serían las recomendadas.
            Los clientes deben de usar cubre bocas, especialmente en los procedimientos de cejas porque, a
            menudo les producen estornudos. Los nervios trigéminos transmiten sensaciones de la nariz, las cejas,
            los parpados y otras partes de la cara al cerebro. Cuando esos nervios son estimulados, al arrancar l pelo
            de las cejas, o picar esas zonas pueden provocar los estornudos. Recomendamos colocar una barrera
            plástica o plástico osmótico encima del cubre bocas. Comenta con la clienta acerca de la molestia que
            experimentara.
        </p>
        <p>
            Hay que entender acerca de la posible adherencia y permanencia del COVID-19 en la ropa, en los
            adornos y en los accesorios del artista (trabajadores) en lo que conocemos como fómites. Hay sitios en
            donde se portan uniformes. En dichos sitios hay que evitar el uso de: Batas de tela, tendrá que cubrir su
            ropa de la calle, sobre todo blusas de manga larga. No podrá usar corbatas, ni joyería como gargantillas
            o collares. Los hombres no podrán tener bigote o barba. No tan solo por el coronavirus sino por otros
            microorganismos que ahí se alojan.
        </p>
        <p>
            Durante el procedimiento a la nueva normalidad, promover que los técnicos trabajadores no compartan
            instrumentos de trabajo y/u objetos personales. Todo debe desinfectarse constantemente. Por razones
            de espacio terminare aquí con este artículo.
        </p>
        <p>
            <b>
                La última recomendación: El cuidado y prevención personal en tanto no se cuente con una vacuna
                preventiva o un medicamento anti viral para Coronavirus SARS Cov-2.
            </b>
        </p>
    </div>
</div>