<div class="row links">
  <div class="row col-12 col-md-6">
    <a *ngFor="let link of links" class="nav-link" href="{{link.link}}" target="blank">{{link.value}}<i
        class="{{link.icon}}"></i></a>
  </div>
  <div class="d-flex flex-row-reverse col-12 col-md-6">
    <a (click)="changeLanguaje('english')" class="nav-link">English</a>
    <a (click)="changeLanguaje('español')" class="nav-link">Español</a>
  </div>
</div>
<nav class="navbar sticky-top navbar-light" style="background-color: white;" (scroll)="onScroll($event)">
  <a *ngIf="navBrand" class="navbar-brand" href="#">
    <img src="/assets/images/{{navBrand}}" [width]="logoWidth" [height]="logoHeight"
      class="d-inline-block align-top brand-logo" alt="">
  </a>
  <div class="row menu">
    <div *ngFor="let menuItem of menu" class="dropdown show">
      <a class="btn dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        {{menuItem.value}}
      </a>
      <div *ngIf="(submenues|submenus:menuItem.value).length > 0" class="dropdown-menu"
        aria-labelledby="dropdownMenuLink">
        <a (click)="changeResource(submenu.value)" *ngFor="let submenu of submenues | submenus:menuItem.value"
          [routerLink]="[submenu.link]" class="dropdown-item" href="#">{{submenu.name}}</a>
      </div>
    </div>
  </div>
</nav>
<div *ngIf="bioShow" id="bioseccontainer">
  <button id="x" (click)="closeBioSecurity()">
    X
  </button>
  <a *ngIf="languaje=='english'" routerLink="/biosecurity" href="#" (click)="closeBioSecurity()">Biosecurity information</a> 
  <a *ngIf="languaje=='español'" routerLink="/biosecurity" href="#" (click)="closeBioSecurity()">Informacion de bioseguridad</a> 
</div>
<main>
  <div class="container-fluid mt-2">
    <div class="main-tabs-docs">
      <router-outlet></router-outlet>
    </div>
  </div>
</main>
<nav class="navbar sticky-bottom navbar-light" style="background-color: black;">
  <div class="row d-flex flex-row-reverse col-12">
    <!-- <a href="http://cosmeticredit.com/applynow.html" target="blank">
      <img src="/assets/images/creditCards.png" class="img-fluid rounded">
    </a> -->
  </div>
</nav>