import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { ActiveresourceService } from '../activeresource.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private dataservice: DataService,
    private resource: ActiveresourceService,
  ) { }

  briefcaseTitle: String;
  briefcaseData: Object;
  welcomeTitle: String;
  welcomeDescription: String;
  welcomeImage: String;
  expertiseSectionTitle: String;
  expertise1Title: string;
  expertise1Description: String;
  expertise1Image: String;
  expertise2Title: string;
  expertise2Description: String;
  expertise2Image: String;
  expertise3Title: String;
  expertise3Description: String;
  expertise3Image: String;
  more: String;
  ubicationTitle:String;
  hours:object;

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.resource.getActiveResource().subscribe(activeres => {
      this.dataservice.getResources(activeres.languaje).subscribe(
        data => {
          this.welcomeTitle = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('welcome') && s.resourceType.includes('title'))[0].value,
            this.welcomeDescription = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('welcome') && s.resourceType.includes('description'))[0].value,
            this.expertiseSectionTitle = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expertise') && s.resourceType.includes('title'))[0].value,
            this.briefcaseTitle = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('briefcase') && s.resourceType.includes('title'))[0].value,
            this.briefcaseData = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('briefcase') && s.resourceType.includes('image')),
            this.expertise1Title = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert1') && s.resourceType.includes('title'))[0].value,
            this.expertise1Description = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert1') && s.resourceType.includes('description'))[0].value,
            this.expertise1Image = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert1') && s.resourceType.includes('image'))[0].value,
            this.expertise2Title = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert2') && s.resourceType.includes('title'))[0].value,
            this.expertise2Description = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert2') && s.resourceType.includes('description'))[0].value,
            this.expertise2Image = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert2') && s.resourceType.includes('image'))[0].value,
            this.expertise3Title = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert3') && s.resourceType.includes('title'))[0].value,
            this.expertise3Description = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert3') && s.resourceType.includes('description'))[0].value
            this.expertise3Image = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('expert3') && s.resourceType.includes('image'))[0].value,
            this.more = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('more') && s.resourceType.includes('title'))[0].value,
            this.welcomeImage = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('welcome') && s.resourceType.includes('image'))[0].value
            this.ubicationTitle = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('ubication') && s.resourceType.includes('title'))[0].value
            this.hours = data._embedded.resources.filter(s => s.page.includes('home') && s.section.includes('hours'))
        }
      )
    });
  }

  changeResource(res:String):void{
    this.resource.setActiveResource(res);
  }
  
}
