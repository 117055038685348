import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActiveresourceService } from '../activeresource.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  title: String;
  image: String;
  description: String;

  constructor(private dataservice: DataService,
    private resource: ActiveresourceService,) {

  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.resource.getActiveResource().subscribe(activeres => {
      this.dataservice.getResources(activeres.languaje).subscribe(
        data => {
          this.title = data._embedded.resources.filter(s => s.page.includes('about') && s.section.includes('title') && s.resourceType.includes('text'))[0].value,
          this.image = data._embedded.resources.filter(s => s.page.includes('about') && s.section.includes('image') && s.resourceType.includes('image'))[0].value,        
          this.description = data._embedded.resources.filter(s => s.page.includes('about') && s.section.includes('description') && s.resourceType.includes('text'))[0].value
        }
      )
    });
  } 

}
