<div class="tab-content">
    <div *ngIf="!testimonialsData" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="testimonialsData" class="row">
        <div class="col-12">
            <h2 *ngIf="languaje=='english'" class="primary-heading">Testimonials</h2>
            <h2 *ngIf="languaje=='español'" class="primary-heading">Testimonios</h2>
        </div>
        <div *ngIf="testimonialsData" class="col-md-9 offset-md-2 col-12">
            <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li *ngFor="let slide of testimonialsData; let i = index" [attr.data-slide-to]="i"
                        data-target="#carouselIndicators" class="active carInd"></li>
                </ol>
                <div class="carousel-inner imgCar">
                    <div *ngFor="let slide of testimonialsData; let i = index" [ngClass]="{active: i==0}"
                        class="carousel-item">
                        <img class="d-block mx-auto w-100 " src="/assets/images/{{slide.testimony}}">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>{{slide.person}}</h5>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>