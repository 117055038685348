<div class="tab-content">
  <div *ngIf="!treatmentName" class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div *ngIf="treatmentName" class="row">
    <div class="col-12">
      <h2 class="primary-heading">{{treatmentName}}</h2>
    </div>
    <div *ngIf="notes.length>0" class="col-12 col-sm-8">
      <div *ngIf="treatmentDescription" class="col-12"> {{treatmentDescription}}</div>
      <hr>
      <div *ngFor="let treat of treatments; let i = index" class="col-12">
        <h4 class="primary-heading">{{treat.procname}}</h4>
        <div>
          <img *ngIf="getProcedureImage(treat.procedure)!='none'" [src]="getProcedureImage(treat.procedure)"
            class="image-fluid rounded mx-auto d-block" style="max-width: 100%; height: auto;">
        </div>
        <!--<div innerHTML='{{treat.description}}'></div>-->
        <table *ngIf="treat.procedure" class="table table-striped">
          <tbody>
            <tr *ngFor="let detail of treat.procedures" (click)="open(mymodal,$event,detail.text)"> 
              <th scope="row" class="procTitle">{{detail.text}}</th> 
              <td>{{detail.price}}</td>
              <td>
                <img *ngIf="detail.image" class="image-fluid rounded mx-auto d-block preview" src="/assets/images/{{detail.image}}" />
              </td>
            </tr>
          </tbody>
        </table>
        <hr *ngIf="i%2==0" class="hrR">
        <hr *ngIf="i%2>0" class="hrL">
      </div>
    </div>
    <div *ngIf="notes.length==0" class="col-12 col-sm-12">
      <div *ngIf="treatmentDescription" class="col-12"> {{treatmentDescription}}</div>
      <hr>
      <div *ngFor="let treat of treatments; let i = index" class="col-12">
        <h4 class="primary-heading">{{treat.procname}}</h4>
        <img *ngIf="getProcedureImage(treat.procedure)!='none'" [src]="getProcedureImage(treat.procedure)"
          class="image-fluid rounded mx-auto d-block" style="max-width: 100%; height: auto;">
         <!--<div innerHTML='{{treat.description}}'></div>-->
         <table class="table table-striped">
          <tbody>
            <tr *ngFor="let detail of treat.procedures">
              <th scope="row">{{detail.text}}</th> 
              <td>{{detail.price}}</td>
              <td><img *ngIf="detail.image"
                  class="image-fluid rounded mx-auto d-block preview" src="/assets/images/{{detail.image}}" /></td>
            </tr>
          </tbody>
        </table>
        <hr *ngIf="i%2==0" class="hrR">
        <hr *ngIf="i%2>0" class="hrL">
      </div>
    </div>
    <div *ngIf="notes.length>0" class="card h-50 w-100 col-12 col-sm-4 border-danger">
      <div class="card-body">
        <div *ngFor="let note of notes" class="col-12">
          <h4 class="primary-heading">{{note.procedure}}</h4>
          <div innerHTML='{{note.description}}'></div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{selectedPreviewTitle}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="row text-center">
          <div class="col-12">
            <img *ngIf="selectedPreview" class="image-fluid rounded mx-auto d-block previewShow" src="{{selectedPreview}}" />
          </div>
          <div></div>
      </div>
  </div>
</ng-template>