import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActiveresourceService } from '../activeresource.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(
    private dataservice: DataService,
    private resource: ActiveresourceService,
    private router: Router,) {

  }

  faqList: object;
  faqTitle: String;

  ngOnInit(): void {
    this.resource.getActiveResource().subscribe(activeres => {
      if (activeres.resource.includes("home")) { 
        activeres.resource="Permanent cosmetic make-up";
      } 
      this.dataservice.getFaqs(activeres.resource, activeres.languaje).subscribe(
        data => {
          if (data._embedded.faqs.length > 0) {
            this.faqList = data._embedded.faqs.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            });
            this.faqTitle = activeres.resource;
          } else {
            this.faqList = undefined;
            this.faqTitle = "";
          }
        }
      );
    })
  }

}
