import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguajeService {

  constructor() { }

  private languaje:  ReplaySubject<String> = new ReplaySubject<String>();

  public setLanguaje(languaje:String) {
    this.languaje.next(languaje);
  }


  public getLanguaje(): Observable<String> {
    return this.languaje.asObservable();
  }
}
