import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActiveresourceService } from '../activeresource.service';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit {

  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('modalReservation') modalReservation: any;

  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    allDaySlot: false,
    eventOverlap: false,
    dateClick: this.handleDateClick.bind(this)
  };

  closeResult: string;
  title: String;
  selectedDate: Date;
  calendarApi;
  treatments: object;
  treatment:String;
  patient:String;
  email:String;
  terms:boolean=false;

  constructor(private dataservice: DataService,
    private modalService: NgbModal,
    private resource: ActiveresourceService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.resource.getActiveResource().subscribe(activeres => {
      this.title = activeres.resource
      this.dataservice.getAllTreatments(activeres.languaje).subscribe(
        data => {
          console.log(data);
          if (data._embedded.treatments.length > 0) {
            this.treatments = data._embedded.treatments.filter(s => s.procedure.indexOf("Notes") == -1 && s.procedure.indexOf("Notas") == -1).filter(s => s.procedure.indexOf("Important") == -1 && s.procedure.indexOf("Importante") == -1).filter(s => s.type == null).sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            });
          } else {
            this.treatments = undefined;
          }
        }
      )
    })
  }

  handleDateClick(arg) {
    this.calendarApi = this.calendarComponent.getApi();
    this.selectedDate = arg.date;
    this.open(this.modalReservation);
  }


  open(content) {
    this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (result === "save") {
        this.save();
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): void {
    if (reason === ModalDismissReasons.ESC) {
      this.selectedDate = null;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.selectedDate = null;
    }
    if (reason === "cancel") {
      this.selectedDate = null;
    }
    if (reason === "save") {
      this.save();
    }
  }

  private save(): void {
    this.calendarApi.addEvent({ title: this.patient + "-" + this.treatment, date: this.selectedDate });
    this.patient="";
  };

  changeTreatment(e) {
    this.treatment= e.target.value;
  }

}
