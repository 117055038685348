import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from './data.service';
import { ActiveresourceService } from './activeresource.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'loremy';
  constructor(
    private dataservice: DataService,
    private activeResource: ActiveresourceService,
    private route: Router,) {
  }
 
  navBrand: String;
  menu: Object;
  phoneNumber: String;
  socialLinks: Object;
  submenues: Object;
  links: object;
  treatment: String;
  logoWidth: Number;
  logoHeight: Number;
  bioShow :boolean = true;
  languaje:String="english";

  ngOnInit(): void {
    this.activeResource.setActiveResourceLanguaje("english", "home");
    this.load();
  }

  load(): void {

    //450 150
    this.logoWidth = 450;
    this.logoHeight = 150;
    this.activeResource.getActiveResource().subscribe(res => {
      this.languaje=res.languaje;
      this.dataservice.getResources(res.languaje).subscribe(
        data => {
          this.navBrand = data._embedded.resources.filter(s => s.page.includes('nav') && s.section.includes('brand') && s.resourceType.includes('image'))[0].value;
          this.menu = data._embedded.resources.filter(s => s.page.includes('nav') && s.section.includes('menu') && s.resourceType.includes('items')).sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1; 
            }
            return 0;
          });
          this.phoneNumber = data._embedded.resources.filter(s => s.page.includes('nav') && s.section.includes('phone') && s.resourceType.includes('description'))[0].value;
          this.socialLinks = data._embedded.resources.filter(s => s.page.includes('nav') && s.section.includes('links') && s.resourceType.includes('items'));
          this.submenues = data._embedded.resources.filter(s => s.page.includes('nav') && s.resourceType.includes('submenu')).sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            return 0;
          }); 
          this.links = data._embedded.resources.filter(s => s.page.includes('nav') && s.resourceType.includes('link')).sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            return 0;
          });
        }
      )
    });
  }

  changeLanguaje(lang: String): void {
    this.activeResource.setActiveLanguaje(lang);
  }

  changeResource(res: String): void {
    this.activeResource.setActiveResource(res);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (window.pageYOffset === 0 && this.route.url === "/") {
      this.logoWidth = 450;
      this.logoHeight = 150;
    } else {
      //261 97
      this.logoWidth = 225;
      this.logoHeight = 75;
    }
  }

  closeBioSecurity(){
    this.bioShow=false;
  }

}
