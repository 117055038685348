import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TreatmentsComponent } from './treatments/treatments.component';
import { FaqComponent } from './faq/faq.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { AboutComponent } from './about/about.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PretreatmentComponent } from './pretreatment/pretreatment.component';
import { BiosecurityComponent } from './biosecurity/biosecurity.component';


const routes: Routes = [ 
  { path: '', component: HomeComponent},
  { path: 'treatments', component: TreatmentsComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'gallery', component: GalleryComponent},
  { path: 'reservations', component: ReservationsComponent},
  { path: 'about', component: AboutComponent},
  { path: 'testimonials', component: TestimonialsComponent},
  { path: 'pretreatment', component: PretreatmentComponent},
  { path: 'biosecurity', component: BiosecurityComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
