import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActiveresourceService } from '../activeresource.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  constructor(
    private dataservice: DataService,
    private resource: ActiveresourceService,
  ) { }

  testimonialsData: Object;
  languaje:String;

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.resource.getActiveResource().subscribe(activeres => {
      this.languaje=activeres.languaje;
      this.dataservice.getTestimonials().subscribe(
        data => {
            this.testimonialsData = data._embedded.testimonials
        }
      )
    });
  }

}
