import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActiveresourceService } from '../activeresource.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  closeResult: string;
  image:String;
  title:String;
  description:String;

  constructor(private dataservice: DataService,private modalService: NgbModal,
    private resource: ActiveresourceService) {

  }

  images: object;
  gallery:String;

  ngOnInit(): void {
    this.resource.getActiveResource().subscribe(activeres => {
      if (activeres.resource.includes("home")){
        activeres.resource="eyes";
      }
      this.gallery = activeres.resource
      this.dataservice.getGalleries(this.gallery).subscribe(
        data => {
          console.log(data);
          this.images = data._embedded.galleries;
          console.log(this.images);
        }
      )
    })
  }

  open(content,title:String,image:String,description:String) {
    this.title=title;
    this.image=image;
    this.description=description;
    this.modalService.open(content, {size:'xl' ,ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
