<full-calendar #calendar [options]="calendarOptions"></full-calendar>
<ng-template #modalReservation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{selectedDate | date:'EEEE, MMMM d, y, h:mm:ss a'}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="exampleInputPassword1">Full name</label>
                <input [(ngModel)]="patient" name="patient" class="form-control" id="exampleInputPassword1"
                    placeholder="Enter your full name">
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input [(ngModel)]="email" name="email" type="email" class="form-control" id="exampleInputEmail1"
                    aria-describedby="emailHelp" placeholder="Enter email">
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                    else.</small>
                <!--<button  class="btn btn-secondary"> Verify</button> -->
            </div>
            <div class="form-group">
                <label class="form-check-label" for="treatment">Treatment</label>
                <select class="form-control form-control-lg" (change)="changeTreatment($event)" id="treatment">
                    <option>Select treatment</option>
                    <option *ngFor="let treatment of treatments">{{treatment.procname}}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-check-label"><input [(ngModel)]="terms" type="checkbox" name="terms"> I accept the next terms and conditions:</label>
                <div class="termsandconditions">
                    <a class="nav-link" href="/assets/documents/appointmentPolicy.pdf" target="blank">Appointment policy</a>
                    <a class="nav-link" href="/assets/documents/preprocedure.pdf" target="blank">Pre-procedure information</a>
                    <a class="nav-link" href="/assets/documents/HealingSchedule.pdf" target="blank">Healing schedule</a>
                    <a class="nav-link" href="/assets/documents/AfterCare.pdf" target="blank">After care instructions</a>
                </div>
            </div>
            <button type="button" class="btn btn-primary" [disabled]="!terms" (click)="modal.close('save')">Save</button>
            <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Cancel</button>
        </form>

        <!--
 <div class="row text-center">
            <div *ngIf="treatments" class="col-12">
                <div class="row">
                    <label>Nombre</label>
                    <input [(ngModel)]="patient">
                </div>
                <div class="row">
                    <label>Tratamiento</label>
                    <select class="form-control form-control-lg" (change)="changeTreatment($event)">
                        <option>Select treatment</option>
                        <option *ngFor="let treatment of treatments">{{treatment.procname}}</option>
                    </select>
                </div>
                <div class="row">
                    <label>Correo electronico</label>
                    <input type="email" [(ngModel)]="email">
                </div>
            </div>
            <div></div>
        </div>
        -->

    </div>
    <!--
<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('save')">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('cancel')">Cancel</button>
    </div>
    -->

</ng-template>