<div class="tab-content">
    <div *ngIf="!images" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="row text-center text-lg-left">
        <div *ngFor="let image of images" class="col-lg-3 col-md-4 col-6">
            <a (click)="open(mymodal,image.title,image.image,image.description)" class="d-block mb-4 h-100">
                <img class="img-fluid img-thumbnail" src="/assets/images/{{image.image}}" alt="">
            </a>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row text-center">
            <div class="col-12">
                <img class="img-fluid img-thumbnail" src="/assets/images/{{image}}" alt="">
                <p>{{description}}</p>
            </div>
            <div></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>