<div *ngIf="!welcomeTitle" class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
<div *ngIf="welcomeTitle" class="tab-content">
  <div class="row">
    <div *ngIf="welcomeImage" class="col-md-6 col-12">
      <img src="/assets/images/{{welcomeImage}}" class="img-fluid rounded mx-auto d-block imgWel">
    </div>
    <div class="col-md-6 col-12" style="text-align: justify;">
      <h2 class="primary-heading">{{welcomeTitle}}</h2>
      {{welcomeDescription}}
    </div>
  </div>
  <hr class="hrR">
  <div class="row">
    <div class="col-md-12 col-12" style="text-align: center;">
      <h2 class="primary-heading">{{expertiseSectionTitle}}</h2>
    </div>
    <div class="col-md-12 col-12">
      <div *ngIf="expertiseSectionTitle" class="card-group">
        <div class="card">
          <img class="card-img-top imgExp1" src="/assets/images/{{expertise1Image}}">
          <div class="card-body">
            <h5 class="card-title">{{expertise1Title}}</h5>
            <p class="card-text" style="text-align: justify;">{{expertise1Description}}</p>
            <p class="card-text"><small class="text-muted"><a (click)="changeResource(expertise1Title)" [routerLink]="['/treatments']">{{more}}</a></small></p>
          </div>
        </div>
        <div class="card">
          <img class="card-img-top imgExp2" src="/assets/images/{{expertise2Image}}">
          <div class="card-body">
            <h5 class="card-title">{{expertise2Title}}</h5>
            <p class="card-text" style="text-align: justify;">{{expertise2Description}}</p>
            <p class="card-text"><small class="text-muted"><a (click)="changeResource(expertise2Title)" [routerLink]="['/treatments']">{{more}}</a></small></p>
          </div>
        </div>
        <div class="card">
          <img class="card-img-top imgExp3" src="/assets/images/{{expertise3Image}}">
          <div class="card-body">
            <h5 class="card-title">{{expertise3Title}}</h5>
            <p class="card-text" style="text-align: justify;">{{expertise3Description}}</p>
            <p class="card-text"><small class="text-muted"><a (click)="changeResource(expertise3Title)" [routerLink]="['/treatments']">{{more}}</a></small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="hrL">
  <div class="row">
    <div class="col-md-12 col-12" style="text-align: center;">
      <h2 class="primary-heading">{{briefcaseTitle}}</h2>
    </div>
    <div *ngIf="briefcaseData" class="col-md-6 offset-md-3 col-12">
      <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li *ngFor="let slide of briefcaseData; let i = index" [attr.data-slide-to]="i"
            data-target="#carouselIndicators" class="active carInd"></li>
        </ol>
        <div class="carousel-inner imgCar">
          <div *ngFor="let slide of briefcaseData; let i = index" [ngClass]="{active: i==0}" class="carousel-item">
            <img class="d-block mx-auto w-100 imgCar" src="/assets/images/{{slide.value}}">
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
  <hr class="hrR">
  <div class="row">
    <div class="col-md-12 col-12" style="text-align: center;"> 
      <h2 class="primary-heading">{{ubicationTitle}}</h2>
    </div>
    <div class="col-12" style="text-align: center;">
      <a class="nav-link" href="https://www.google.com/maps/place/Permanent+Cosmetic+Make+Up+%26+Skin+Care+by+Loremy+Spa/@41.907643,-87.901488,16z/data=!4m5!3m4!1s0x0:0x5794ead529cf9451!8m2!3d41.9076429!4d-87.9014882?hl=es-419" target="blank">36 E North Ave, Northlake, IL 60164</a> 
      <div class="col-md-6 offset-md-3 col-12">
        <table class="table">
          <tbody>
            <tr *ngFor="let hour of hours">
              <th scope="row">{{hour.resourceType}}</th>
              <td>{{hour.value}}</td> 
            </tr> 
          </tbody> 
        </table>
      </div>
    </div>
  </div>

</div>