<div class="tab-content">
    <div *ngIf="languaje=='english'">
        <h2>Pre-Treatment Advice and Procedures</h2>
        <ul>
            <li>
                Since delicate skin or sensitive areas may swell slightly, or redden, it is advised not to make social plans
                for the same day. Lip liner may appear "crusty for up to a week.
            </li>
            <li>
                Please wear your normal make-up to the procedure. If you are having lips or bows done please bring your
                favorite pencils.
            </li>
            <li>
                If unwanted hair is normally removed in the area to be treated, ie; tweezing or waxing, the hair removal
                should be done at least 24 hours prior to your procedure. Electrolysis should not be done within five days of
                the procedure. Do not resume any method of hair removal for a week after the procedure. 4) If eyelashes or
                eyebrows are normally dyed, do not have that procedure done within
        
                48 hours of this procedure. Wait one week after the eyebrow or eyeliner procedure
        
                before dying lashes or brows.
            </li>
            <li>
                If you are wearing contact lenses and are having eyeliner done, do not wear your lenses to your appointment
                and do not replace them until the day after the procedure.
            </li>
            <li>
                If you are having the eyeliner procedure done, as a safety precaution, in case of watering or swelling, we
                recommend that you have someone available, or accompany you, who could drive you home if you so decide, or if it
                is necessary.
            </li>
            <li>
                If you are having lip liner done and have had previous problems with cold sores, fever blisters, or mouth
                ulcers, the procedure is likely to re-activate the problem. Your Intradermal Cosmetic technician can make
                recommendations to help prevent or minimize the outbreak.
            </li>
            <li>
                We recommend allergy testing of pigment before the planned procedure.
            </li>
            <li>
                Do not use aspirin or ibuprofen for 7 days prior to your procedure.
            </li>
        </ul>
        <p>
            We look forward to working with you. If you have any questions, please call or make notes so we can discuss them
            with you when you arrive for your appointment.
        </p>
    </div>
    <div *ngIf="languaje!='english'">
        <h2>Instrucciones de Pre-Procedimiento</h2>
        <ul>
            <li>
                Dado que la piel delicada o zonas sensibles pueden hincharse un poco, o enrojecerse, se recomienda no hacer planes sociales para el mismo día. Delineador de labios puede aparecer "costra" por un máximo de una semana.
            </li>
            <li>
                Por favor use su maquillaje normal a su cita de procedimiento. Si se va a delinear los labios o las cejas favor de traer sus lápices favoritos. 3) Si el vello no deseado es normalmente removido en la zona a tratar, es decir, con pinzas o
        
                cera, la depilación se debe hacer al menos 24 horas antes de su procedimiento. La electrólisis no
                
                se debe hacer dentro de los cinco dias antes del procedimiento. No reanude cualquier método de
                
                depilación hasta una semana después del procedimiento.
            </li>
            <li>
                Si las pestañas y las cejas son normalmente teñidas, no debe de hacerse ese procedimiento dentro de las 48 horas antes del delineado. Espere una semana después de la ceja o delineadores de ojos procedimiento ante las pestañas o las cejas morir.
            </li>
            <li>
                Si usted está usando lentes de contacto y están teniendo el delineado de ojos hecho, no use los lentes a su cita y no los reemplazan, hasta el dia después del procedimiento.
                Si usted está recibiendo el procedimiento delineador de ojos hecho, como medida de seguridad, en caso de riesgo o hinchazón, le recomendamos tener a alguien disponible, o acompañar a usted, que podía llevar a su casa si así lo deciden, o si es necesario.
            </li>
            <li>
                Si tiene los labios de linea hecho y han tenido problemas anteriores con el herpes labial, ampollas de fiebre o úlceras en la boca, el procedimiento es probable que vuelva a activar el problema. Su intradérmicas técnico de cosmética puede hacer recomendaciones para ayudar a prevenir o minimizar el brote
            </li>
            <li>
                Se recomienda pruebas de alergia de pigmento antes del procedimiento previsto.
            </li>
            <li>
                No use aspirina o el ibuprofeno durante 7 días antes de su procedimiento.
            </li>
        </ul>
        <p>
            Esperamos con interés trabajar con usted. Si usted tiene alguna pregunta, favor de llamar o escribir notas para que podamos discutir con usted cuando llegue a su cita.
        </p>
    </div>
</div>