import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'submenus'
})
export class SubmenusPipe implements PipeTransform {

  transform(items: any, filter: String): any {
    if (!items || !filter) {
        return items;
    }
    return items.filter(item => item.section.includes(filter));
}

}
