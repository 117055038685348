import { Component, OnInit } from '@angular/core';
import { ActiveresourceService } from '../activeresource.service';

@Component({
  selector: 'app-biosecurity',
  templateUrl: './biosecurity.component.html',
  styleUrls: ['./biosecurity.component.css']
})
export class BiosecurityComponent implements OnInit {

  constructor(private resource: ActiveresourceService) { }

  languaje:String;

  ngOnInit(): void {
    this.load();
  }

  load():void{
    this.resource.setActiveResource("pretreatment");
    this.resource.getActiveResource().subscribe(activeres => {
      this.languaje=activeres.languaje;
    });
  }

}
