import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs/index'

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }
  private baseUrl: string = 'http://206.189.182.29:8080'
  //private baseUrl: string = 'http://localhost:8080'
 
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('Ladmin:JvIGU4h2V5')
    })
  };

  //getData
  getResources(lang:String): Observable<any> {
    const usersUrl = this.baseUrl + "/resources/search/findBylanguaje?languaje="+lang
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

  getTreatments(treatment:String,lang:String): Observable<any> {
    const usersUrl = this.baseUrl + "/treatments/search/findByTreatmentlanguaje?languaje="+lang+"&treatment="+treatment
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

  getAllTreatments(lang:String): Observable<any> {
    const usersUrl = this.baseUrl + "/treatments/search/findBylanguaje?languaje="+lang
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

  getFaqs(treatment:String,lang:String): Observable<any> {
    const usersUrl = this.baseUrl + "/faqs/search/findBylanguaje?treatment="+treatment+"&languaje="+lang
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

  getGalleries(gallery:String): Observable<any> {
    const usersUrl = this.baseUrl + "/galleries/search/findByGallery?gallery="+gallery
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

  getProceduresDetails(lang:String): Observable<any> { 
    const usersUrl = this.baseUrl + "/procedures/search/findBylanguaje?languaje="+lang
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

  getTestimonials(): Observable<any> {
    const usersUrl = this.baseUrl + "/testimonials"
    return this.http.get<any>(usersUrl, this.httpOptions);
  }

}