import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { ActiveresourceService } from '../activeresource.service';
import { Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.css']
})
export class TreatmentsComponent implements OnInit {

  constructor(
    private dataservice: DataService,
    private resource: ActiveresourceService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load():void{
    this.resource.getActiveResource().subscribe(activeres => {
      if (activeres.resource.includes("home")){
        activeres.resource="Permanent cosmetic make-up";
      }
      this.treatment = activeres.resource
      this.dataservice.getTreatments(activeres.resource, activeres.languaje).subscribe(
        data => {
          if(data._embedded.treatments.length>0){
            this.treatments = data._embedded.treatments.filter(s => s.treatment.includes(this.treatment)).filter(s => s.procedure.indexOf("Notes")==-1 && s.procedure.indexOf("Notas")==-1).filter(s => s.procedure.indexOf("Important")==-1 && s.procedure.indexOf("Importante")==-1).filter(s => s.procedure.indexOf("Description")==-1).filter(s => s.type== null).sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            });
            this.treatmentImages = data._embedded.treatments.filter(s => s.type!= null).filter(s => s.type.includes("image"));
            this.notes = data._embedded.treatments.filter(s => s.treatment.includes(this.treatment)).filter(s => s.procedure.includes("Notes")||s.procedure.includes("Important")||s.procedure.includes("Notas")||s.procedure.includes("Importante"));
            this.treatmentName = this.treatments[0].name;
            if(data._embedded.treatments.filter(s => s.treatment.includes(this.treatment)).filter(s => s.procedure.includes("Description")||s.procedure.includes("Descripcion")).length>0){
              this.treatmentDescription = data._embedded.treatments.filter(s => s.treatment.includes(this.treatment)).filter(s => s.procedure.includes("Description")||s.procedure.includes("Descripcion"))[0].description;
            }else{
              this.treatmentDescription = "";
            }
          }else{
            this.notes = undefined;
            this.treatments = undefined;
            this.treatmentName = "";
          }
        }
      );
      this.dataservice.getProceduresDetails(activeres.languaje).subscribe(
        data => {
          if(data._embedded.procedures.length>0){
            this.proceduresDetails = data._embedded.procedures.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            });
            this.treatments.forEach(treat => {
              treat.procedures=this.getProcedureDetails(treat.procedure);
            });
            console.log(this.treatments);
          }else{
            this.proceduresDetails = undefined;
          }
        }
      );
    })
  }
  
  treatments: Array<any>;
  treatmentImages:Array<any>
  treatment: String;
  treatmentName: String;
  notes:Array<object>;
  treatmentDescription:String;
  proceduresDetails:Array<any>;
  selectedPreview:String;
  selectedPreviewTitle:String;

  getProcedureImage(procedure:String):String{
    if(this.treatmentImages.filter(s=>s.procedure.includes(procedure))[0]){
      return "/assets/images/"+this.treatmentImages.filter(s=>s.procedure.includes(procedure))[0].description;
    }else{
      return "none";
    }
  }

  getProcedureDetails(procedure:String):Array<any>{
      if(this.proceduresDetails){ 
        return this.proceduresDetails.filter(s=>s.procedure===procedure);
      }
  }

  open(content,parent:any,title:String) {
    if(parent.currentTarget.querySelector('.preview')){
      this.selectedPreviewTitle=title;
      this.selectedPreview=parent.currentTarget.querySelector('.preview').src;
      this.modalService.open(content, {size:'xl' ,ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        //on open
      }, (reason) => {
        //on close 
      });
    }
  }
}
