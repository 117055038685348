<div class="tab-content">
    <div *ngIf="!faqTitle" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="faqTitle" class="row">
        <div class="col-12">
            <h2 class="primary-heading">{{faqTitle}}</h2>
        </div>
        <div class="col-12">
            <div id="accordion">
                <div *ngFor="let faqElement of faqList" class="card">
                    <div class="card-header" id="heading{{faqElement.order}}">
                        <h5 [class]="faqElement.order=='1'?'mb-0 show':'mb-0'" data-toggle="collapse"
                            [attr.data-target]="'#collapse'+faqElement.order"
                            [attr.aria-expanded]="faqElement.order=='1'"
                            [attr.aria-controls]="'collapse'+faqElement.order">
                            <div>{{faqElement.question}}</div>
                        </h5>
                    </div>

                    <div id="collapse{{faqElement.order}}" [class]="faqElement.order=='1'?'collapse show':'collapse'"
                        [attr.aria-labelledby]="'heading'+faqElement.order" data-parent="#accordion">
                        <div class="card-body" innerHTML=' {{faqElement.response}}'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>