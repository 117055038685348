import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Resource } from './shared/models/resource.model';

@Injectable({
  providedIn: 'root'
})
export class ActiveresourceService {

  constructor() { }

  private activeResource:  ReplaySubject<Resource> = new ReplaySubject<Resource>();
  private active:Resource = new Resource;

  public setActiveResourceLanguaje(languaje:String,res:String) {
    this.active.languaje=languaje;
    this.active.resource=res;
    this.activeResource.next(this.active);
  }

  public setActiveLanguaje(languaje:String) {
    this.active.languaje=languaje;
    this.activeResource.next(this.active);
  }

  public setActiveResource(res:String) {
    this.active.resource=res;
    this.activeResource.next(this.active);
  }

  public getActiveResource(): Observable<Resource> {
    return this.activeResource.asObservable();
  }

  
  
}
